



















import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { Picker } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { Iterator, LinkForm, SetForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { blogFeedContentFactory } from '../BlogFeed.factory'
import { BlogFeedModule, BlogFeedModuleContent } from '../BlogFeed.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'BlogFeedModuleForm',
  components: { FormFieldset, LinkForm, Iterator, SetForm }
})
export class BlogFeedModuleForm extends AbstractModuleForm<BlogFeedModule> {
  //
  protected initialContent: BlogFeedModuleContent = blogFeedContentFactory()

  public icons = DashmixIconName
  public picker = Picker
}
export default BlogFeedModuleForm
